import React from "react";
import './textarea.css'

function TextArea(props) {
    const { label, name, moreDeatials, value, defaultValue, handleChange, error, readOnly } = props;

    return (
        <div className="textarea">

            <div className="textarea-group">
                <label class="form-label" >{label}</label>
                <span style={{"fontSize": "12px", "fontWeight": '600'}}>{moreDeatials}</span>
                <textarea
                    minLength="1"
                    name={name}
                    className="text-control"
                    onChange={handleChange}
                    defaultValue={defaultValue}
                    value={value}
                    disabled={readOnly}
                ></textarea>
                
                <span id="error">{error}</span>
            </div>
        </div>
    )

}

export default TextArea