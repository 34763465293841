import React, { useState, useEffect } from 'react'
import './topup.css'
import { useDispatch, useSelector } from 'react-redux';
// import {submitMpesaRequest, getAccountBalance, submitOrderRequest, convertToUsd} from '../../apis/Topup'

const Topup = () => {

  const dispatch = useDispatch()
  const selector = useSelector((state) => state.auth)
  // const userDetails = JSON.parse(selector?.userInfo)
  const userDetails = selector?.userInfo
  const action = useSelector(state => state.action)


  const [mpesaInfo, setMpesaInfo] = useState({ phoneNumber: '', amount: 0 });
  const [pesapalOrder, setPesapalOrder] = useState({ phone_number: '', amount: 0, email_address:''})
  const [formErrors, setFormErrors] = useState()

  const [showConfirm, setShowConfirm] = useState(false)

  // const [accountBalance,setAccountBalance] = useState()
  const [confirmed, setConfirmed] = useState(false)
  const [successful, setSuccessful] = useState(false)
  const [unSuccessful, setUnSuccessful] = useState(false)
  const [convertedAmount, setConvertedAmount] = useState('')

  const [stkPush, setStkPush] = useState(true)
  const [paybill, setPaybill] = useState(false)
  const [pesapal, setPesapal] = useState(false)
  const [confirmOrder, setConfirmOrder] = useState(false)

  const handleMpesaOnChange = (e) => {
    setMpesaInfo({ ...mpesaInfo, [e.target.name]: e.target.value })
  }

  const handlePesapalOnChange = (e) => {
    setPesapalOrder({ ...pesapalOrder, [e.target.name]:  e.target.value })
  }

  const loading = () =>{
    return <div class="lds-dual-ring"></div>
}

useEffect(() => {
  setConvertedAmount(10)
  // getAccountBalance(dispatch).then(resp => {
  //   if (resp?.data?.status === "success") { 
  //     setAccountBalance(resp?.data?.data)
  //   }
  // })
}, [dispatch])


  const convertMpesaTOUsd = (e) => {
    // if(e.target.value){
    //   convertToUsd(dispatch, e.target.value).then(resp => {
    //    resp?.status===200? setConvertedAmount(resp?.data?.data?.amount):toast.error('Unexpected error occurred!')
    //    })
    // }else{
    //   convertToUsd(dispatch, 0).then(resp => {
    //     setConvertedAmount(resp?.data?.data?.amount)
    //    })
    // }
         
    }


  // Validating form
  const validate = () => {
    let errors = {}

    if(stkPush){
      if (!mpesaInfo?.amount || mpesaInfo?.amount < 1) {
        errors['mpesaamount'] = 'Amount cannot be less than 1!'
      }
      if (!mpesaInfo?.phoneNumber) {
        errors['phoneNumber'] = 'Phone number is required!'
      }
      else if(!/^254\d{9}$/.test(mpesaInfo?.phoneNumber)){
        errors['phoneNumber'] = 'Invalid phone number. E.g. 254712345678'
      }
    
    }

    if(pesapal){
      if (!pesapalOrder?.amount || pesapalOrder?.amount < 0.01) {
        errors['amount'] = 'Amount cannot be less than 1!'
      }
      if (!pesapalOrder?.phone_number) {
        errors['phone_number'] = 'Phone number is required!'
      }
     
      else if (!/^7\d{8}$/.test(pesapalOrder?.phone_number)){
        errors['phone_number'] = 'Invalid phone number. E.g. 712345678'
      }
      if (!pesapalOrder?.email_address) {
        errors['email_address'] = 'Email is required!'
      }
      else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(pesapalOrder?.email_address)) {
        errors['email_address'] = 'Invalid email address. E.g. example@email.com'
    }
    }

    setFormErrors(errors)
    return errors
  };

  // Topup credits
  const handleTopupCredits = () => {
    if (Object.keys(validate()).length === 0) {
      // submitMpesaRequest(dispatch, mpesaInfo).then(resp => {
      //   if (resp?.data?.status === "success") {
      //       setShowConfirm(true)
      //       setFormErrors([])
      //       document.getElementById("form-modal").reset();
      //   }
      //   else {
      //     toast.error("Error occured!", { theme: "colored" })
      //   }
      // })
    }
  }

  const handleMpesaConfirmTopup = () =>{
    // getAccountBalance(dispatch).then(resp => {
    //   if (resp?.data?.status === "success") { 
    //     if(resp?.data?.data?.credits > accountBalance?.credits){
    //       setConfirmed(true)
    //       setSuccessful(true)
    //     }
    //     else if(resp?.data?.data?.credits === accountBalance?.credits){
    //       setConfirmed(true)
    //       setUnSuccessful(true)
    //     }
    //     else{
    //       setConfirmed(true)
    //       setUnSuccessful(true)
    //     }
    //   }
    // })
  }

  const handleSubmitOrder = () => {
    if (Object.keys(validate()).length === 0) {
      setConfirmOrder(true)
    }
  }

  const handleConfirmOrder = () =>{ 
      // submitOrderRequest(dispatch, pesapalOrder).then(resp => {
      //   if(resp.status === 200){
      //     window.location.href = resp?.data?.redirect_url;
      //   }else{
      //     toast.error('Unexpected error occurred!')
      //   }
      // })
  }


  // const handleConfirmOrder = () => {
  //   submitOrderRequest(dispatch, pesapalOrder).then(resp => {
  //     if (resp.status === 200) {
  //       const width = 800;
  //       const height = 600;
  //       const left = (window.innerWidth - width) / 2;
  //       const top = (window.innerHeight - height) / 2;

  //       const popupFeatures = `width=${width},height=${height},scrollbars=yes,resizable=yes,top=${top},left=${left}`;

  //       const popupWindow = window.open(resp?.data?.redirect_url, 'PopupWindow', popupFeatures);
  
  //       if (popupWindow) {
  //         // Popup window opened successfully
  //         popupWindow.focus();
  //       } else {
  //         // Popup blocked by browser
  //         window.location.href = resp?.data?.redirect_url;
  //       }
  //     } else {
  //       toast.error('Unexpected error occurred!');
  //     }
  //   });
  // };

  const resetStatusValues = ()=>{
    setSuccessful(false)
    setUnSuccessful(false)
    setConfirmed(false)
  }

  const handleCloseModal = (dispatch) => {
    setSuccessful(false)
    setUnSuccessful(false)
    setConfirmed(false)
    setShowConfirm(false)
    document.getElementById("form-modal").reset();
  }

  

  return (
    <div className="pricing-page">
      
      <div className="payment-card">
       
        <p className="payment-card-title">Select Topup Option</p>
        <div className='select-option-section'>
           <p id="option-title-btn" className={`stkpush-${stkPush}`} onClick={() => {setStkPush(true); setPaybill(false); setPesapal(false)}}>Mpesa Xpress</p>
           <p id="option-title-btn" className={`paybill-${paybill}`} onClick={() => {setStkPush(false); setPaybill(true); setPesapal(false)}}>Paybill</p>
           <p id="option-title-btn" className={`pesapal-${pesapal}`} onClick={() => {setStkPush(false); setPaybill(false); setPesapal(true)}}>Card Payment</p>
        </div>

        <form id='form-modal' className='topup-form'>

    
     {stkPush?
      // ------- Mpesa xpress topup form --------
      <div>
        <p className="payment-notify"><b> NB: This option applies to the Kenyan market only.</b><br/>Enter details below and an STK push will be sent to your phone from MPESA to complete the payment.
            The amount will be converted to USD and applied to your balance</p>

       <div className="payment-input-group">
        <p className="payment-label">Mpesa Number</p>
        <input className="payment-input" name='phoneNumber' type="number" minLength={9} onChange={(e) => handleMpesaOnChange(e)} /><br />
        <span id='error'>{formErrors?.phoneNumber}</span>
      </div>
   
      <div className="payment-input-group">
        <p className="payment-label">Amount (KES)</p>
        <input className="payment-input" name='amount' type="number" min={5} onChange={(e) => {handleMpesaOnChange(e); convertMpesaTOUsd(e)}} /><br />
        <span id='error'>{formErrors?.mpesaamount}</span>
      </div>
      <div className="payment-input-group">
        <p className="payment-label">Amount (USD)</p>
        <input className="payment-input" name='amount' type="number" value={convertedAmount} readonly disabled style={{backgroundColor: '#DCDCDC'}}/><br />
      </div>

      <div>
        {!action.pending ?
          <button type='button' className="payment-btn" onClick={handleTopupCredits}>TopUp</button> :
          <button type='button' className="payment-btn btn-disabled" disabled="disabled">{loading()}</button>
        }
      </div>
    </div>:

     // ------- Paybill topup form --------
     paybill?
     <div className='paybill-section'>
     <ol className="option1-list">
       <li>Go to Mpesa</li>
       <li>Select Lipa na Mpesa follow by Paybill</li>
       <li>Enter business number <span id="paybill">4030071 (Lipachat Limited)</span></li>
       <li>Enter account number <span id="paybill">{userDetails?.client?.name}</span></li>
       <li>Enter Amount</li>
       <li>Enter PIN</li>
     </ol>
   </div> :

// ------- Pesapal topup form --------
   <div>

      <div className="payment-input-group">
        <p className="payment-label">Amount (USD)</p>
        <input className="payment-input" name='amount' type="number" onChange={(e) => handlePesapalOnChange(e)} /><br />
        <span id='error'>{formErrors?.amount}</span>
      </div>

      <div className="payment-input-group">
        <p className="payment-label">Phone Number</p>
        <input className="payment-input" name='phone_number' type="number" onChange={(e) => handlePesapalOnChange(e)}
         placeholder='712345678' /><br />
        <span id='error'>{formErrors?.phone_number}</span>
      </div>

      <div className="payment-input-group">
        <p className="payment-label">Email</p>
        <input className="payment-input" name='email_address' type="email"  onChange={(e) => handlePesapalOnChange(e)} /><br />
        <span id='error'>{formErrors?.email_address}</span>
      </div>

    <div>
        <button type='button' className="payment-btn" onClick={handleSubmitOrder}>Submit Order</button>
    </div>

    </div>
  
  }
       
        </form>
      </div>


      {/* /// Confirm mpesa order request  */}
      <div className={showConfirm ? 'confirm-modal-active': 'confirm-modal-inactive'}>
            <div className="confirm-modal" >
                <div className='mpesa-modal-title'>
                <span id="action-title">Confirm Topup</span>
                <span id='close-button' class="material-symbols-outlined" onClick={() => handleCloseModal(dispatch)}>close</span>
                </div>
                <p className='confirm-message'>Request received, you will receive a prompt. Please enter your pin to complete 
                the topup. Once done, click the button below to confirm.</p>

                <div className='ta-buttons'>

                {!confirmed ?
                <button type='button' className="payment-btn cconfirm-button" onClick={handleMpesaConfirmTopup}>Confirm</button> :
                 unSuccessful ?
                <button type='button' className="payment-btn" onClick={() => {handleTopupCredits(); resetStatusValues()}}>Resend Prompt</button>:
                <button type='button' className="payment-btn" onClick={()=>window.location.reload()}>Continue</button>
              }
              {!action.pending ? "" : <p style={{marginTop: '20px', fontSize: '14px', color:'#F4C430', fontWeight: '600'}}>Confirming topup...please wait</p> }
              {!successful ? "" : <p style={{marginTop: '20px', fontSize: '14px', color:'green', fontWeight: '600'}}>Top-up of Ksh.{convertedAmount} was successful.</p> }
              {!unSuccessful ? "" : <p style={{marginTop: '20px', fontSize: '14px', color:'red', fontWeight: '600'}}>
              Top-up was unsuccessful. Something went wrong!</p> }

                </div>
            </div>
        </div>




      {/* /// Confirm pesapal order request  */}
      <div className={confirmOrder ? 'session-modal-active' : 'create-modal-inactive'}>
        <div className="session-modal" >
          <span className="session-title">Notice</span>
          <p className='session-message'>You are now leaving our website You will be redirected to Pesapal website for payment.</p>
          <div className='session-buttons'>

          
            {!action.pending?
                <button type='button' className="session-dismiss-button" onClick={handleConfirmOrder}>Continue</button>:
                <button className="session-dismiss-disabled session-dismiss-button" disabled={true}>{loading()}</button>
            }
            
            <button type='button' className="redirect-hide-button" onClick={() => setConfirmOrder(false)}>Go Back </button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Topup