import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux/ActionSlice";

export const getAllUsers = async (dispatch, page, rows) => {
    dispatch(actionStart())
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL + `/user?page=${page}&per_page=${rows}`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }

}


export const createUser = async (user, dispatch) => {
    dispatch(actionStart())
    try {
        const resp = await axios.post(process.env.REACT_APP_BASE_URL + '/user', user, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}

export const updateUser = async (user, dispatch) => {
    dispatch(actionStart())
    try {
        const resp = axios.put(process.env.REACT_APP_BASE_URL + '/user', user, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}

export const suspendUser = async (dispatch, id, user) => {
    dispatch(actionStart())
    try {
        const resp = axios.put(process.env.REACT_APP_BASE_URL + `/user/${id}/suspend`, user, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}

export const deleteUser = async (dispatch, id, user) => {
    dispatch(actionStart())
    try {
        const resp = axios({ method: 'delete', url: process.env.REACT_APP_BASE_URL + '/user/'+id, data: user, 
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}

// export const getUserInfo = async (token, dispatch) => {
//     const cancelToken = axios.CancelToken.source()
//     dispatch(actionStart())
//     try {
//         const resp = await axios.get(process.env.REACT_APP_BASE_URL + '/user/' + token, {
//             cancelToken: cancelToken.token,
//             headers: {
//                 'accept': 'application/json',
//                 'content-type': 'application/json',
//                 'Authorization': 'Bearer ' + sessionStorage.getItem('token')
//             }
//         });
//         return resp
//     } catch (err) {
//         dispatch(actionFailed())
//         return err
//     }
// }

export const completeRegistration = async (user, dispatch) => {
    const cancelToken = axios.CancelToken.source()
    try {
        return await axios.get(process.env.REACT_APP_BASE_URL + '/registration/complete' + user, {
            cancelToken: cancelToken.token,
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
            }
        });
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}