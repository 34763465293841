import React,  { useState, useEffect } from 'react';
import './analytics.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useDispatch } from 'react-redux';
import { getAnalytics } from '../../apis/Analytics'
import dayjs from 'dayjs';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Analytics = () => {

  const dispatch = useDispatch()

  const [allAnalytics, setAllAnalytics] = useState();

  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    getAnalytics(dispatch, dayjs(startDate).format('DD-MM-YYYY'), dayjs(endDate).format('DD-MM-YYYY')).then(resp => {
      if (resp?.status === 200) { setAllAnalytics(resp.data.data); }
      else { toast.error("Error occured...", { theme: "colored" }) }
    })

  }, [dispatch, startDate, endDate])


  return (
    <div className="analytics-grid">


      <div className='analyticst-date-range-section'>
        
        <p>Date range filter</p>
       <div className='analyticst-date-range-filter'>
        <div className='data-picker-from'>
          <p>Start Date</p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker format="DD/MM/YYYY" value={dayjs(startDate)} onChange={(newValue) => setStartDate(newValue)}/>
          </LocalizationProvider>
        </div>

        <div className='data-picker-to'>
          <p>End Date</p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker format="DD/MM/YYYY" value={dayjs(endDate)} onChange={(newValue) => setEndDate(newValue)}/>
          </LocalizationProvider>
        </div>
      </div>
        
      </div>
        
  <div className='conversations-overview'>
        <div className="analytics-card">
            <p className='analytic-title'>Total conversations</p>
            <p className='analytic-value'>{allAnalytics?.totalConversations?.count}</p>
            <p>{allAnalytics?.totalConversations?.percentage}%</p>
        </div>

        <div className="analytics-card">
            <p className='analytic-title'>Open</p>
            <p className='analytic-value'>{allAnalytics?.open?.count}</p>
            <p>{allAnalytics?.open?.percentage}%</p>
        </div>

        <div className="analytics-card">
            <p className='analytic-title'>Closed</p>
            <p className='analytic-value'>{allAnalytics?.closed?.count}</p>
            <p>{allAnalytics?.closed?.percentage}%</p>
        </div>

        <div className="analytics-card">
            <p className='analytic-title'>Pending</p>
            <p className='analytic-value'>{allAnalytics?.pending?.count}</p>
            <p>{allAnalytics?.pending?.percentage}%</p>
        </div>

        <div className="analytics-card">
            <p className='analytic-title'>Expired</p>
            <p className='analytic-value'>{allAnalytics?.expired?.count}</p>
            <p>{allAnalytics?.expired?.percentage}%</p>
        </div>

        <div className="analytics-card">
            <p className='analytic-title'>Expired without Reply</p>
            <p className='analytic-value'>{allAnalytics?.expiredWithoutReply?.count}</p>
            <p>{allAnalytics?.expiredWithoutReply?.percentage}%</p>
        </div>
    
</div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

    </div>
  );
}

export default Analytics;
