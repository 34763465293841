import React, { useState } from "react";
import './input.css';

const FileInput = (props) => {
    const { label, name, accept, errorMessage, handleChange , uploading } = props;
    const [selectedFile, setSelectedFile] = useState('');

    // Handle file input change
    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file.name);
            // Call handleChange if it is provided
            if (handleChange) {
                handleChange(event);
            }
        } else {
            setSelectedFile('');
        }
    };

    return (
        <div className='file-import-sec'>
            <label htmlFor="file-import-btn" id='file-import-label'>
                {selectedFile ? <strong>{selectedFile}</strong> : <span>{label}</span>}
            </label>
            <input
                type="file"
                name={name}
                hidden
                id="file-import-btn"
                accept={accept}
                onChange={onFileChange}
            />
            {uploading? (<p className="uploading-indicator">
                <i className="pi pi-spin pi-spinner-dotted" style={{ fontSize:'1rem', marginRight: '4px' }}></i> Uploading file...</p>): ''}
            <span id="error">{errorMessage}</span>
        </div>
    );
};

export default FileInput;
