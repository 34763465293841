import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux/ActionSlice";


export const getAllBroadcast = async (dispatch, page, rows) => {
    dispatch(actionStart())
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL + `/broadcast?page=${page}&per_page=${rows}`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }

}


export const sendBroadcast = async (dispatch, broadcast) => {
    dispatch(actionStart())
    try {
        const broadcastData = new FormData();
        broadcastData.append('name', broadcast.name); // Example name
        broadcastData.append('templateName', broadcast.templateName); // Example template name
        broadcastData.append('contactsFile', broadcast.contactsFile);
        broadcastData.append('selectedContacts', broadcast.selectedContacts? JSON.stringify(broadcast.selectedContacts) : null);
        broadcastData.append('mediaUrl', broadcast.mediaUrl);
        const resp = await axios.post(process.env.REACT_APP_BASE_URL + '/broadcast', broadcastData, {
            headers: {
                'Content-Type':'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}