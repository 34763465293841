import React, { useState, useEffect } from 'react'
import "../../components/ReUsedComponents/table.css"

import { Table, Button, Pagination, Spin, Modal, Badge }from "antd";

import Input from '../ReUsedComponents/inputField/Input'
import FileInput from '../ReUsedComponents/inputField/FileInput'

import { getAllBroadcast, sendBroadcast } from '../../apis/BroadcastAPI';
import { getAllContacts } from '../../apis/ContactsAPI';
import { getApprovedTemplates } from '../../apis/TemplatesAPI';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import SearchSelector from '../ReUsedComponents/searchSelector/SearchSelector';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Broadcast = () => {

    const dispatch = useDispatch()
    const action = useSelector((state) => state.action)

    const [broadcastModalState, setBroadcastModalState] = useState(false);
   
    const [pageSize, setPageSize] = useState(25);
    const [pageNo, setPageNo] = useState(1);

    const [pageSizeContacts, setPageSizeContacts] = useState(25);
    const [pageNoContacts, setPageNoContacts] = useState(1);

    const [formErrors, setFormErrors] = useState()

    const [allBroadcast, setAllBroadcast] = useState([]);
    const [allTemplates, setAllTemplates] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null)

    const [name, setName] = useState();
    const [templateName, setTemplateName] = useState();
    const [mediaFile, setMediaFile] = useState();
    const [contactsFile, setContactsFile] = useState();
    const [mediaFileError, setMediaFileError] = useState()
    const [selectedContacts, setSelectedContacts] = useState([])

    const selectedKeys = selectedContacts.map((contact) => contact.phoneNumber);

    useEffect(() => {
        getAllBroadcast(dispatch, pageNo, pageSize).then(resp => {
            if (resp?.status === 200) { setAllBroadcast(resp?.data);}
            else { toast.error("Error occured...", { theme: "colored" }) }
          })
        getApprovedTemplates(dispatch).then(resp => {
          if (resp?.status === 200) { setAllTemplates(resp?.data?.data); }
          else { toast.error("Error occured...", { theme: "colored" }) }
        })
        getAllContacts(dispatch, pageNoContacts, pageSizeContacts).then(resp => {
          if (resp?.status === 200) { 
            setAllContacts(resp?.data); 
          }
          else { toast.error("Error occured...", { theme: "colored" }) }
        })
    }, [dispatch, pageSize, pageNo, pageNoContacts, pageSizeContacts])

    const validate = () => {
      let errors = {}
      if (!name) {
        errors['name'] = 'Name is required!'
      }
      if (!templateName) {
        errors['template'] = 'Template message is required!'
      }
      if (!contactsFile && selectedContacts.length === 0) {
        errors['contactsFile'] = 'Please upload contacts file or select from table!'
      }
    
      setFormErrors(errors)
      return errors
    };

    const handleOnSelect = (option) => {
      setTemplateName(option.name); 
      setSelectedTemplate(option)
    };

    const handleSendBroadcast = () => {
      if (Object.keys(validate()).length === 0) {
        sendBroadcast(dispatch, {name: name, templateName: templateName, contactsFile: contactsFile, selectedContacts: selectedContacts, mediaUrl: mediaFile}).then(res =>{
          if (res?.status === 200) {
            handleCloseModal(dispatch)
            toast.success("Sent successful!", { theme: "colored" })
            window.location.reload()
        }else{
          toast.error(res?.response?.data.message, { theme: "colored" })
          setMediaFileError(res?.response?.data.message)
        }
        })
       }
    };

    const columns2 = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 250,
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: 'Contacts',
        dataIndex: 'contacts',
        key: 'contacts',
        render: (_, record) => <span>{record.result.sent}</span>,
        sorter: (a, b) => a.phoneNumber.length - b.phoneNumber.length,
      },
      {
        title: 'Delivered',
        dataIndex: 'delivered',
        key: 'delivered',
        render: (_, record) => <span>{record.result.sent}</span>,
        sorter: (a, b) => a.group.length - b.group.length
      },
      {
        title: 'Sent',
        dataIndex: 'sent',
        key: 'sent',
        render: (_, record) => <span>{record.result.sent}</span>,
        sorter: (a, b) => a.createdAt - b.createdAt,
      },
      {
        title: 'Pending',
        dataIndex: 'pending',
        key: 'pending',
        render: (_, record) => <span>{record.result.pending}</span>,
        sorter: (a, b) => a.updatedAt.length - b.updatedAt.length,
      },
      {
        title: 'Failed',
        dataIndex: 'failed',
        key: 'failed',
        render: (_, record) => <span>{record.result.failed}</span>,
        sorter: (a, b) => a.updatedAt.length - b.updatedAt.length,
      },
      {
        render: (_, record) => <span>{<Badge count={record.status} style={{ backgroundColor: '#52c41a',fontSize: '10px', fontWeight:'bold' }}/>}</span>,
        sorter: (a, b) => a.updatedAt.length - b.updatedAt.length,
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, record) => <span>{dateFormat(record.createdAt, "dd/mm/yyyy HH:MM")}</span>,
        sorter: (a, b) => a.updatedAt.length - b.updatedAt.length,
      },
    ];

    const contactsColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        sorter: (a, b) => a.phoneNumber.length - b.phoneNumber.length,
      },
      {
        title: 'Group',
        dataIndex: 'group',
        key: 'group',
        sorter: (a, b) => a.group.length - b.group.length
      },
    ];
  
    const handlePaginationChange = (page, size) => {
      setPageNo(page);
      setPageSize(size);
    };

    const handleContactsPaginationChange = (page, size) => {
      setPageNoContacts(page);
      setPageSizeContacts(size);
    };

    const rowSelection = {
    selectedRowKeys: selectedKeys, // Bind selected keys explicitly
    onChange: (selectedKeys, selectedRows) => {
      const selectedData = selectedRows.map((row) => ({
        name: row.name,
        phoneNumber: row.phoneNumber,
      }));
      setSelectedContacts(selectedData); // Update state with selected rows
    },
  };

    const handleCloseModal = () => {
      setFormErrors([])
      setBroadcastModalState(false)
      setName();
      setTemplateName();
      setMediaFile();
      setContactsFile();
      setSelectedContacts([])
      setSelectedTemplate()
      document.getElementById("form-modal").reset();
    }

    return (
        <div class='data-table-page' style={{ height: '85vh'}}>

            <div className='data-table-actions-sections'>  
                <p className='data-table-page-title' style={{marginLeft: '11px'}}>Broadcasts</p>     
                <Button type="primary" onClick={() => setBroadcastModalState(true)} >Send Broadcast</Button>
            </div> 
           
            <div className='data-table-section' style={{ height: '75vh' }}> 
                <Spin spinning={action.pending}>
                    <Table dataSource={allBroadcast?.data} columns={columns2}
                      pagination={false}
                      scroll={{ y: 'calc(75vh - 100px)',}}
                      sticky
                      className="custom-table"
                    /> 
                    <div style={{ padding: '10px 5px', textAlign: 'center' }}>
                    <Pagination
                      className="custom-pagination"
                      align='end'
                      total={allBroadcast?.totalElements}
                      showTotal={(_, range) => `${range[0]}-${range[1]} of ${allBroadcast?.totalElements} items`}
                      current={pageNo}
                      pageSize={pageSize}
                      onChange={handlePaginationChange}
                      onShowSizeChange={(current, size) => {
                        setPageSize(size);
                        setPageNo(1);
                      }}
                      showSizeChanger
                    />
                    </div>
                  </Spin>   
                </div> 

  {/* /////////////////////SEND BROADCAST //////////// */}

  <Modal title="Send Broadcast" open={broadcastModalState} okText="Submit" onOk={handleSendBroadcast} confirmLoading={action.pending}
      onCancel={() => handleCloseModal()} width={800}  style={{top: 20,}}>
        <form id="form-modal">
          <div className='broadcast-info'>
                <Input label="Name *" name='name' value={name} type='text' handleChange={(e) => setName(e.target.value)} errorMessage={formErrors?.name}/>
                <SearchSelector options={allTemplates.data} label="Template*" onSelect={handleOnSelect} name="templateName" 
                  value={templateName} errorMessage={formErrors?.template}/>
           </div>
           {selectedTemplate && selectedTemplate?.components.find(component => component.type === "HEADER").format !== "TEXT" &&(
                  <div style={{marginBottom: '10px'}}>
                  <p style={{fontSize: '14px', fontWeight: '600'}}>Media File</p>
                  <span style={{fontSize: '12px', fontWeight: '600'}}>*Required only when template type is MEDIA</span>
                  
                  <FileInput label="Upload media file" name='image' handleChange={(e)=>setMediaFile(e.target.files[0])}
                          accept="image/*" uploading={false} selectedFile={mediaFile} errorMessage={mediaFileError}/>
                </div>
            )}

          <div className='broadcast-contacts'> 
             <p style={{"fontSize":"14px", "fontWeight":"600"}}>Contacts <span style={{"fontSize":"11px", "fontWeight":"600"}}>(Upload or select from exisitng contacts)</span>*</p>

            <div className='import-sec'>
              <a className="template-link" href={process.env.PUBLIC_URL + "/samplebroadcastfile.csv"}>
              <i className="pi pi-file" style={{ 'fontSize': '1em' }} />Download contacts file template</a>

              <label for="import-btn" id='import-label'>
                <i className="pi pi-download" style={{ 'fontSize': '1.5em', 'fontWeight': '200' }} /><br />
                {contactsFile ? <strong>{contactsFile?.name}</strong> : <span>Upload contacts</span>}
              </label>
              <input type="file" name="contactsFile" hidden accept=".xlsx, .csv" id="import-btn"
                onChange={(e) => setContactsFile(e.target.files[0])}/>
                <span id="error">{formErrors?.contactsFile}</span>
            </div>

            <div className='broadcast-contacts-table'> 
                <Spin spinning={action.pending}>
                    <Table dataSource={allContacts?.data} columns={contactsColumns}
                      pagination={false}
                      scroll={{ y: 250}}
                      sticky
                      className="custom-table-broadcast"
                      rowSelection={{
                        type: 'checkbox', // Use "radio" for single selection
                        ...rowSelection,
                      }}
                       rowKey="phoneNumber"
                    /> 
                    <div style={{ padding: '6px 5px', textAlign: 'center' }}>
                    <Pagination
                      className="custom-pagination"
                      align='end'
                      total={allContacts?.totalElements}
                      showTotal={(_, range) => `${range[0]}-${range[1]} of ${allContacts?.totalElements} items`}
                      current={pageNoContacts}
                      pageSize={pageSizeContacts}
                      onChange={handleContactsPaginationChange}
                      onShowSizeChange={(current, size) => {
                        setPageSize(size);
                        setPageNo(1);
                      }}
                      showSizeChanger
                    />
                    </div>
                  </Spin>   
              </div>
          </div> 
       </form>   
    </Modal>


      <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>
    )
}

export default Broadcast