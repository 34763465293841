import React, { useState, useEffect } from 'react'
import "../../components/ReUsedComponents/table.css"

import { Space, Table, Button, Pagination, Spin, Modal, Tooltip, Badge, Popconfirm }from "antd";
import { EditOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import dateFormat from 'dateformat';

import Input from '../ReUsedComponents/inputField/Input'
import RoleSelectField from '../ReUsedComponents/selectField/RoleSelectField'

import { getAllUsers, createUser, updateUser, suspendUser, deleteUser } from '../../apis/UsersAPI';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const UserManagement = () => {

  const dispatch = useDispatch()
  const action = useSelector((state) => state.action)

  const [createModalState, setCreateModalState] = useState(false);
  const [editModalState, setEditModalState] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  
  const [allUsers, setAllUsers] = useState([])
  const [user, setUser] = useState({ name: '', username: '', roleId: ''});
  const [formErrors, setFormErrors] = useState()

  // Getting all users
  useEffect(() => {
    getAllUsers(dispatch, pageNo, pageSize).then(resp => {
      if (resp?.status === 200) { setAllUsers(resp?.data) }
      else { toast.error("unable to fetch users!", { theme: "colored" }) }
    })
  }, [dispatch, pageNo, pageSize])

  const handleOnChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
      }
    
  const validate = () => {
    let errors = {}
    if (!user?.name) {
      errors['name'] = 'Name is required!'
    }
    if (!user?.username) {
      errors['username'] = 'Email is required!'
    }
    if (!user?.roleId) {
      errors['roleId'] = 'Role is required!'
    }
    setFormErrors(errors)
    return errors
  };
    
  const handleCloseModal = () => {
    setUser({ name: '', username: '', roleId: ''})
    setFormErrors([])
    setCreateModalState(false)
    setEditModalState(false)
  }

   // Creating new user
   const handleCreateUser = () => {
    if (Object.keys(validate()).length === 0) { 
      createUser(user, dispatch).then(resp => {
        if (resp?.status === 200) {
          //handleCloseModal(dispatch)
          toast.success("Created successful!", { theme: "colored" })
          window.location.reload()
        }
      })
    }
  }
    
  // Updating user
  const handleUpdateUser = () => {
    if (Object.keys(validate()).length === 0) {
      updateUser(user, dispatch).then(resp => {
        if (resp?.status === 200) {
          // handleCloseModal(dispatch)
          toast.success("Updated successful!", { theme: "colored" })
          window.location.reload()
        }
        else {  }
      })
    }
  }

  const handleSuspendUser = (id, user) => {
    suspendUser(dispatch, id, user).then(resp => {
      if (resp?.status === 200) {
        // handleCloseModal(dispatch)
        toast.success("User suspended!", { theme: "colored" })
        // window.location.reload()
      }
      else {
        toast.error("Error occured!", { theme: "colored" })
      }
    })
  }

  const handleDeleteUser = (id, user) => {
    deleteUser(dispatch, id, user).then(resp => {
      if (resp?.status === 200) {
        // handleCloseModal(dispatch)
        toast.success("Deleted successful!", { theme: "colored" })
        window.location.reload()
      }
      else {
        toast.error("Error occured!", { theme: "colored" })
      }
    })
  }

  const columns2 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Email',
      dataIndex: 'username',
      key: 'username',
      width: 250,
      sorter: (a, b) => a.username.length - b.username.length,
    },
    {
      title: 'Role',
      dataIndex: 'roleId',
      key: 'roleId',
      render: (_, record) => <span>{record.role?.name}</span>,
      sorter: (a, b) => a.roleId.length - b.roleId.length
    },
    {
      title: 'Status',
      dataIndex: 'loginStatus',
      key: 'loginStatus',
      render: (_, record) => <span>{record.loginStatus === "ONLINE" ?<Badge count={record.loginStatus} style={{width: '75px', backgroundColor: '#52c41a',fontSize: '10px', fontWeight:'bold' }}/>
      :<Badge count={record.loginStatus} style={{ width: '75px', fontSize: '10px', fontWeight:'bold'}} />}</span>,
      sorter: (a, b) => a.loginStatus.length - b.loginStatus.length,
    }, 
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => <span>{dateFormat(record.createdAt, "dd/mm/yyyy HH:MM")}</span>,
      sorter: (a, b) => a.updatedAt.length - b.updatedAt.length,
    },
    {
      title: 'Last updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => <span>{dateFormat(record.updatedAt, "dd/mm/yyyy HH:MM")}</span>,
      sorter: (a, b) => a.updatedAt.length - b.updatedAt.length,
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'x',
      width: 120,
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Update">
            <EditOutlined style={{ fontSize: '20px', color: '#1890ff' }} onClick={() => {setEditModalState(true); setUser({id: record?.id ,name: record.name, roleId: record.role?.id, username: record.username}) }}/>
          </Tooltip>

           {record.userStatus === "DEACTIVATED"? 
                 <Tooltip title="Unsuspend user">
                 <CheckCircleOutlined style={{ fontSize: '20px', color: '#008000' }} onClick={() => {setEditModalState(true); setUser({id: record.id ,name: record.name, roleId: record.role?.id, username: record.username}) }}/>
                 </Tooltip>:
                <Tooltip title="Suspend User">
                  <CloseCircleOutlined style={{ fontSize: '20px', color: '#f6be10' }} onClick={() => handleSuspendUser(record?.id, {name: record.name, roleId: record.role?.id, username: record.username})} />
                </Tooltip>
              }

            <Tooltip title="Delete">
              <Popconfirm
                title="Delete user"
                description="Are you sure you want to delete this user?"
                onConfirm={() => handleDeleteUser(record.id, {name: record.name, roleId: record.role?.id, username: record.username})}
                okText="Yes"
                cancelText="No">
              <DeleteOutlined style={{ fontSize: '20px', color: '#ff4d4f' }}/>
              </Popconfirm>
          </Tooltip>
  
        </Space>
      )
    }, 
  ];

  const handlePaginationChange = (page, size) => {
    setPageNo(page);
    setPageSize(size);
  };

    return (
        <div class='data-table-page' style={{ height: '85vh'}}>

           <div className='data-table-actions-sections'>  
                <p className='data-table-page-title' style={{marginLeft: '11px'}}>Users </p>     
                <Button type="primary" onClick={() => setCreateModalState(true)}>Create User</Button>
            </div> 
           
         <div className='data-table-section' style={{ height: '75vh' }}> 
          <Spin spinning={action.pending}>
              <Table dataSource={allUsers?.data} columns={columns2}
                pagination={false}
                scroll={{ y: 'calc(75vh - 100px)',}}
                sticky
                className="custom-table"
              /> 
              <div style={{ padding: '10px 5px', textAlign: 'center' }}>
              <Pagination
                className="custom-pagination"
                align='end'
                total={allUsers?.totalElements}
                showTotal={(_, range) => `${range[0]}-${range[1]} of ${allUsers?.totalElements} items`}
                current={pageNo}
                pageSize={pageSize}
                onChange={handlePaginationChange}
                onShowSizeChange={(current, size) => {
                  setPageSize(size);
                  setPageNo(1);
                }}
                showSizeChanger
              />
              </div>
            </Spin>
            </div>  


          {/* /////////////////////CREATING CONTACT //////////// */}
          <Modal title="Create User" open={createModalState} okText="Submit" onOk={handleCreateUser} confirmLoading={action.pending}
            onCancel={() => handleCloseModal()} width={650} >
            <Input label="Name*" name='name' type='text' value={user?.name} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.name} />
            <Input label="Email*" name='username' type='email' value={user?.username} errorMessage={formErrors?.username}
                handleChange={(e) => handleOnChange(e)} placeholder="email@gmail.com"/>
            <RoleSelectField errorMessage={formErrors?.roleId} handleChange={(e) => handleOnChange(e)} />
        </Modal>


          {/* /////////////////////EDITNG USER //////////// */}

          <Modal title="Update User" open={editModalState} okText="Submit" onOk={handleUpdateUser} confirmLoading={action.pending}
            onCancel={() => handleCloseModal()} width={650} >
            <Input label="Name " name='name' type='text' value={user?.name} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.name} />
              <Input label="Email*" name='username' type='email' value={user?.username} errorMessage={formErrors?.username}
                  handleChange={(e) => handleOnChange(e)} placeholder="email@gmail.com"/>
              <RoleSelectField  value={user?.roleId} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.roleId}/>
          </Modal>

          <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


     </div>
    )
}

export default UserManagement