import React from "react";
import './navbar.css'

import { NavLink, Link, useNavigate } from 'react-router-dom'
import { updateLoginStatus } from '../../apis/Session';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/SessionSlice';

const Navbar = ({ toggleSideBar }) => {

  const dispatch = useDispatch()
  const selector = useSelector((state) => state.session)
  let navigate = useNavigate()


  const handleLogout = () => {
    updateLoginStatus(dispatch).then(res => {
      dispatch(logout())
      localStorage.clear()
      navigate("/auth/login")
    })
   
  }

  const togleProfileMenu = () => {
    var x = document.getElementById("myProfile");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  return (
    <div className="navbar">


      {/* left most section */}
      <div className="toggle-section">
        <i onClick={toggleSideBar} id='toggle-icon' className="pi pi-bars" style={{ 'fontSize': '1.5em' }} />
        <img src={process.env.PUBLIC_URL + "/images/lipachat-logo.png"} alt="lipachat" className="company-logo" />

         {/* center/menu section */}
         <div className="menu-navbar-section">
            <NavLink to='/app/team-inbox' className='heading-link'><i className="pi pi-inbox" id='h-icon'/>Inbox</NavLink>
            <NavLink to='/app/contacts' className='heading-link'><i className="pi pi-book" id='h-icon'/>Contacts</NavLink>
            <NavLink to='/app/broadcast' className='heading-link'><i className="pi pi-bullseye" id='h-icon'/>Broadcast</NavLink>           
            <NavLink to='/app/messageTemplate' className='heading-link'><i className="pi pi-copy" id='h-icon'/>Templates</NavLink>
            <NavLink to='/app/analytics' className='heading-link'><i className="pi pi-chart-bar" id='h-icon'/>Analytics</NavLink> 
            {selector?.user?.roleView?.id === 1 || selector?.user?.roleView?.id === 2? 
            <NavLink to='/app/user-management' className='heading-link'><i className="pi pi-users" id='h-icon'/>User Management</NavLink> : ''
            }
            
        </div>
      </div>

      
      {/* right menu */}
      <div className="widgets-section">
       
    
      {/* <div className="balance-section">
         <div>
          <p className="user-conversations">   
            <span id="credit-currency">Free Service Conversations Left:</span>
            <span id="credit-amount">1000</span>
          </p>

          <p className="user-credits">   
            <span id="credit-currency">Credit Balance:</span>
            <span id="credit-amount">$50</span>
            <Link className='topup-btn' to='/app/topup'>Buy Credits</Link>
          </p>
        </div>
      </div> */}


        <div className="dropdown-section">
          <div onClick={togleProfileMenu} className="dropdown-trigger">
            <i id='profile-avatar' className="pi pi-user" style={{ 'fontSize': '1.5em' }} />
          </div>

          <div id="myProfile" className="dropdown-menu-container">
            <div className="dropdown-menu">

              <div className="profile-details">
                <i id='profile-avatar-user' className="pi pi-user" style={{ 'fontSize': '1.5em' }} /> 
                <div style={{paddingLeft: '10px'}}>
                   <span className="profile-name">{selector?.user?.name}</span><br />
                   <span className="role-name">{selector?.user?.roleView?.name} </span>
                </div>
              </div>

                <div className="profile-section-links">
                    <Link className="dropdown-menu-item" to="/app/profile">
                       <i className="pi pi-user" id="dropdown-icon" /> Settings</Link>
                    {/* <a onClick={handleLogout} className="dropdown-menu-item logout" href="/auth/login">
                       <i className="pi pi-sign-out" id="dropdown-icon" /> Logout</a> */}
                       <p onClick={handleLogout} className="dropdown-menu-item logout">
                       <i className="pi pi-sign-out" id="dropdown-icon" /> Logout</p>
                </div>
            </div>

          </div>
        </div>
        
      </div>

    </div>
  )
}

export default Navbar