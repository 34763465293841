import React, { useEffect, useState } from 'react';
import './searchselector.css';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const SearchSelector = ({ options, label, onSelect, name, value, errorMessage }) => {
  
  const [searchTitle, setSearchTitle] = useState('');
  const [selectedTemp, setSelectedTemp] = useState();
  const [filteredItems, setFilteredItems] = useState([]);

  console.log("sfe"+selectedTemp)

  useEffect(() => {
      if (searchTitle.trim() === '') {
        setFilteredItems(options);
      } else {
          const filtered = options.filter(book =>
              book.name.toLowerCase().includes(searchTitle.toLowerCase())
          );
          setFilteredItems(filtered);
      }
  }, [searchTitle, options]);

  const handleSearchInputChange = (e) => {
    setSearchTitle(e.target.value);
    setSelectedTemp(e.target.value)
};

  const handleItemClick = (item) => {
    setSearchTitle('');
    setSelectedTemp(item.name)
    onSelect(item); 
  };

  return (
    <div className='search-book-section'>
                <label className="form-label" >{label}</label>
                <input type='text' name={name} className="search-book-input" value={value}
                    onChange={handleSearchInputChange} placeholder='Search template i.e name' autocomplete="off"
                />
                <span id="error">{errorMessage}</span>
                {searchTitle && (
                    <Grid item xs={14} md={6} className='search-list-section'>
                        <List dense={true}>
                            {filteredItems.length > 0 ?
                                filteredItems.map((item, index) => (
                                    <ListItem key={index} className='book-item' onClick={() => handleItemClick(item)}>
                                        <ListItemText primary={item.name} 
                                        secondary={
                                        <div style={{display: 'flex'}}>
                                         <p style={{marginRight: '12px', fontSize: "12px", fontWeight: '600'}}>Category: {item.category}</p>
                                         <p style={{fontSize: "12px",fontWeight: '600'}}>Type: {(item.components.find(item => item.type === "HEADER") || {}).format}</p>
                                        </div>
                                        } 
                                    />
                                    </ListItem>
                                )) :
                                <p id='not-found-info'>"{searchTitle}" not found!</p>
                            }
                        </List>
                    </Grid>
                )}
            </div>
  );
};

export default SearchSelector;
