import React from 'react'
import './rightsidebar.css'


const RightSidebar = ({contactDetails}) => {

//   const session = useSelector(state => state.session)

  // const togleMenu = () => {
  //   var x = document.getElementById("list-group-items");
  //   var y = document.getElementById("arrow");
  //   if (x.style.display === "block") {
  //     x.style.display = "none";
  //     y.style.transform = "rotate(0deg)";
  //   } else {
  //     x.style.display = "block";
  //     y.style.transform = "rotate(90deg)";
  //   }
  // }

  return (
    <div className='inbox-right-sidebar'>

     <div className='username-status-section'>
      <i class="pi pi-user" />
       <p className='fullname-info'>{contactDetails?.name}<br/><span style={{ fontStyle: 'italic' }}>{contactDetails?.group ? contactDetails?.group : "CRM"}</span></p>
     </div>

     <div className='basic-info-section'>
        <span>Basic Information</span>
        <p>Name: {contactDetails?.name}</p>
        <p>Phone Number: {contactDetails?.phoneNumber}</p>
        <p>Group Name: {contactDetails?.group ? contactDetails?.group : "CRM"}</p>
     </div>

    </div>
  )

}

export default RightSidebar