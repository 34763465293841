import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import './passwordpage.css'

import { getTokenDetails, completeRegistration } from '../../apis/Session';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordPage = () => {

    const dispatch = useDispatch()
    const loginPending = useSelector((state) => state.action)
    let navigate = useNavigate()

    const [userPassword, setUserPassword] = useState('')
    const [userConfirmPassword, setUserConfirmPassword] = useState('')
    const { token } = useParams();
    const [user, setUser] = useState()

    const [formErrors, setFormErrors] = useState()
    
    const loading = () => {
        return <div class="lds-dual-ring"></div>
    }

    useEffect(() => {
        getTokenDetails(dispatch, token).then(resp => {
            if (resp?.status === 200) {
                setUser({username:resp?.data?.data, password:userPassword, confirmPassword: userConfirmPassword, token: token})
            }
            else {
                toast.error(resp.response.data.message, { theme: "colored" })
            }
        })
    }, [dispatch, token, userPassword, userConfirmPassword])


    //Handling form validation
    const validate = () => {
        let errors = {}
        if (!userPassword) {
            errors['password'] = 'Password is required!'
        }
        if (!userConfirmPassword) {
            errors['confirmPassword'] = 'Confirm password is required!'
        }
        else if (userConfirmPassword !== userPassword) {
            errors['confirmPassword'] = 'Passwords do not match!'
        }
        setFormErrors(errors)
        return errors
    };

    const handleViewPassword = () => {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

    /////////Handling password setup////////////////
    const handleSetUserPassword = () => {
        if (Object.keys(validate(user)).length === 0) {
            completeRegistration(dispatch, user).then((resp) => {
                if (resp?.status === 200) {
                    toast.success("Registration successful", { theme: "colored" })
                    navigate('/auth/login')
                }
                else {
                    toast.error("Something went wrong.Try again!", { theme: "colored" })
                }

            })
        }
    }

    return (
        <div className='password-page'>
           
                <div className='password-form-section'>
                    <form className='password-form'>
                    <img src={process.env.PUBLIC_URL + "/images/lipachat-logo.png"} alt="welcome-logo" className="password-lipachat-logo" />
                        <p className='password-form-header'>Password Setup</p>
                    
                        <div className='input-group'>
                            <label className='password-label'>Password</label>
                            <input id="myInput" type="password" name='password' className='password-input' onChange={(e) => setUserPassword(e.target.value)} />
                            <span id='form-error'>{formErrors?.password}</span>

                            <p id="show-password"> 
                            <p><input type="checkbox" onChange={handleViewPassword}/><span>Show Password</span></p>
                            </p>
                        </div>

                        <div className='input-group'>
                            <label className='password-label'>Confirm Password</label>
                            <input id="myInput" type="password" name='confirmPassword' className='password-input' onChange={(e) => setUserConfirmPassword(e.target.value)} />
                            <span id='form-error'>{formErrors?.confirmPassword}</span>
                        </div>

                        <div>
                            {!loginPending.pending ?
                                <button type='button' className="password-button" onClick={handleSetUserPassword}>Complete Registration</button> :
                                <button type='button' className="password-disable" disabled="disabled">{loading()}</button>
                            }
                        </div>
                    </form>
                </div>
           
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default PasswordPage