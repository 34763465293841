import React, {useState, useEffect } from 'react'
import './inboxcontacts.css'

import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';

const InboxContacts = ({contactChats, getMessages, totalChats, unreadChats, styleId}) => {
 
  const selector = useSelector((state) => state.session)
  const chatFilters = ['Assigned to me', 'All chats','Last 24 hours','Open','Pending','Solved','Expired']

  const [filteredContacts, setFilteredContacts] = useState()
  const [selectedChatFilter, setSelectedChatFilter] = useState("All chats")
  
  const togleChatsFilterMenu = () => {
    var x = document.getElementById("chats-filter-list");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  useEffect(() => {
    if(selectedChatFilter === "Open" || selectedChatFilter === "Pending" || selectedChatFilter === "Solved" || selectedChatFilter === "Expired"){
      setFilteredContacts( contactChats?.filter(chat => chat.sessionInfo?.sessionStatus === selectedChatFilter.toUpperCase()))
     }else if(selectedChatFilter === "Assigned to me"){
      setFilteredContacts( contactChats?.filter(chat => chat.sessionInfo?.assignedUser?.name === selector?.user?.name))
     }else{
      setFilteredContacts(contactChats);
     }
  }, [contactChats, selectedChatFilter, selector?.user?.name])


  return (
    <div className='inbox-contacts-section' id={styleId}>
{/* 
         <div className='search-contacts-input'>
             <input type='text' className="s-table-input" placeholder='Search...' />
          </div> */}

      <div className='chats-actions-section'>
          <div>
              <div className="chats-filter-dropdown-section">
                  <div onClick={togleChatsFilterMenu} className="chats-filter-dropdown-trigger">
                    <p className='selected-chat-filter'>{selectedChatFilter} <i className="pi pi-angle-down" id="dropdown-icon" /></p> 
                  </div>
                  <div id="chats-filter-list" className="chats-filter-dropdown-menu-container">
                    <ul className="chats-filter-dropdown-menu"> 
                    {chatFilters.filter(chosen => chosen !== selectedChatFilter).map(filterOption => (
                     <li key={filterOption} onClick={()=>{setSelectedChatFilter(filterOption);  togleChatsFilterMenu()}}>{filterOption}</li>
                      ))}
                    </ul>
                  </div>
                    
                  <di className="chats-count-session">
                    <p className='total-chats-count'>{totalChats} Chats</p>|
                    <p className='total-unread-chats-count'>{unreadChats} Open</p>

                  </di>
              </div>
          </div>
     
    </div>


<div className='contacts-section'>
    {filteredContacts && filteredContacts.length > 0 ?
        filteredContacts.map((chat, index) => 
            <div key={index} className='message-list' onClick={() => getMessages(chat.contact)}>
                <p className="contact-name">{chat.contact.name}</p> 
                <p className="contact-message">{chat.messageView.text}</p> 
                
                <p className='contact-group-name'>Group: {chat.contact.group? chat.contact.group: 'CRM' }</p> 
                <div className='contact-chat-status-info'>
                    <p className={`contact-message-status-${chat.sessionInfo.sessionStatus}`}>{chat.sessionInfo.sessionStatus}</p> 
                    <p className="contact-message-datetime">{dateFormat(chat.messageView.createdAt, "dd/mm/yyyy HH:MM")}</p>
                </div>
            </div>
        ) : 
        <div style={{textAlign: 'center', fontWeight: 'bold'}}>No chats found</div>
      }
</div>

  </div>
  )

}

export default InboxContacts