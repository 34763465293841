import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import Navbar from './components/navbar/Navbar';
import TeamInbox from './components/teamInbox/TeamInbox';
import UserManagement from './components/usermanagement/UserManagement';
import Contacts from './components/contacts/Contacts';
import Analytics from './components/analytics/Analytics';
import Profile from './components/profile/Profile';
import Topup from './components/topup/Topup';
import Broadcast from './components/broadcast/Broadcast';
import MessageTemplate from './components/messageTemplate/MessageTemplate';
import PasswordPage from './components/passwordpage/PasswordPage';

import LoginPage from './components/loginpage/LoginPage';
import Signup from './components/signup/Signup';
import Protected from './components/Protected';
import PageNotFound from './components/pageNotFound/PageNotFound';

import ForgotPassword from './components/forgotpassword/ForgotPassword'
import ResetPassword from './components/resetpassword/ResetPassword';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo, setUserToken, setLoginState, logout } from './redux/SessionSlice';
import { jwtDecode } from "jwt-decode";

import './App.css'


function App() {
  const dispatch = useDispatch()
  const selector = useSelector((state) => state.session)

  let navigate = useNavigate()


  const useBeforeRender = (callback, deps) => {
    const [isRun, setIsRun] = useState(false);
    if (!isRun) {
      callback();
      setIsRun(true);
    }
    useEffect(() => () => setIsRun(false), [])
  };


  useBeforeRender(() => {
    dispatch(setUserToken(localStorage.getItem("token")))
    dispatch(setUserInfo(JSON.parse(localStorage.getItem("userinfo"))))
    dispatch(setLoginState(JSON.parse(localStorage.getItem("loginState"))))
  }, [dispatch]);

  
 
  if (selector.loggedIn === true) {
    const decodedToken = jwtDecode(localStorage.getItem("token"))
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      
        return <div className='session-modal-active'>
          <div className="session-modal" >
            <img id="session-img" src="https://100dayscss.com/codepen/alert.png" width="44" height="38" alt='warning' />
            <span className="session-title">Your session expired</span>
            <p className='session-message'>Please log in to continue using the application.</p>
            <div className='session-buttons'>
              <button type='button' className="session-dismiss-button" onClick={() => { localStorage.clear(); dispatch(logout()); navigate('/auth/login') }}>Log in </button>
            </div>
          </div>
        </div>
    }
  }


  const handleToggleSideBar = () => {
    var y = document.getElementById("toggle-bar");
    if (y.style.display === "block") {
      y.style.display = "none";

    } else {
      y.style.display = "block";
      y.style.position = "absolute"
    }
  }

  const DefaultContainer = () => (
    <div>
      <Navbar toggleSideBar={handleToggleSideBar}/>
      <div className='main-page'>
         <Routes>
            <Route exact path='/team-inbox' element={<Protected isLoggedIn={selector.loggedIn}><TeamInbox /></Protected>} />
            <Route exact path='/user-management' element={<Protected isLoggedIn={selector.loggedIn}><UserManagement /></Protected>} />
            <Route exact path='/broadcast' element={<Protected isLoggedIn={selector.loggedIn}><Broadcast /></Protected>} />
            <Route exact path='/messageTemplate' element={<Protected isLoggedIn={selector.loggedIn}><MessageTemplate /></Protected>} />
            <Route exact path='/contacts' element={<Protected isLoggedIn={selector.loggedIn}><Contacts /></Protected>} />
            <Route exact path='/analytics' element={<Protected isLoggedIn={selector.loggedIn}><Analytics /></Protected>} />
            <Route exact path='/profile' element={<Protected isLoggedIn={selector.loggedIn}><Profile /></Protected>} />
            <Route exact path='/topup' element={<Protected isLoggedIn={selector.loggedIn}><Topup /></Protected>} />
        </Routes>
      </div>

    </div>
  );

  const AuthContainer = () => (

    <Routes>
      <Route exact path='/login' element={<LoginPage />} />
      <Route exact path='/signup' element={<Signup />} />
      <Route exact path='/forgot_pass' element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>

   );

  const NotFoundContainer = () => (
    <Routes>
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>

  );

  return (

    <Routes>
      <Route exact path='auth/*' element={<AuthContainer />} />
      <Route exact path='app/*' element={<DefaultContainer />} />
      <Route exact path='*' element={<NotFoundContainer />} />
      <Route exact path="/" element={<Navigate to="/app/team-inbox" />} />
      <Route exact path='/reset_password/:token' element={<ResetPassword />} />
      <Route exact path='/set_password/:token' element={<PasswordPage />} />

      {/* <Route exact path='/user_signup/:token' element={<PasswordPage />} /> */}
    </Routes>

  );
}

export default App;
