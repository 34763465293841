import React, { useState, useEffect } from 'react'
import "../../components/ReUsedComponents/table.css"

import { Space, Table, Modal, Button, Pagination, Tooltip, Popconfirm, Spin }from "antd";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Input from '../ReUsedComponents/inputField/Input'

import { getAllContacts, createContact, updateContact, deleteContact } from '../../apis/ContactsAPI';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contacts = () => {

    const dispatch = useDispatch()
    const action = useSelector((state) => state.action)

    const [createModalState, setCreateModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);

    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    
    const [contact, setContact] = useState({ name: '', phoneNumber: '', contact: ''});
    const [formErrors, setFormErrors] = useState()
    const [allContacts, setAllContacts] = useState([]);

    useEffect(() => {
        getAllContacts(dispatch, pageNo, pageSize).then(resp => {
          if (resp?.status === 200) { setAllContacts(resp?.data); }
          else { toast.error("unable to fetch contacts!", { theme: "colored" }) }
        })
      
    }, [dispatch, pageSize, pageNo])

    const handleOnChange = (e) => {
      setContact({ ...contact, [e.target.name]: e.target.value })
    }

    const validate = () => {
      let errors = {}
      if (!contact?.name) {
        errors['name'] = 'Name is required!'
      }
      if (!contact?.phoneNumber) {
        errors['number'] = 'Invalid phone number.Eg 254123456789!'
      }
      setFormErrors(errors)
      return errors
    };

    const handleCreateContact = () => {
      if (Object.keys(validate()).length === 0) {
        createContact(contact, dispatch).then(resp => {
          if (resp?.status === 200) {
            if (resp?.data.error === true) {
              toast.error(resp?.data.message, { theme: "colored" })
            } else {
              handleCloseModal(dispatch)
              toast.success("Created successful!", { theme: "colored" })
              window.location.reload()
            }
          } 
          else { toast.error(resp?.response.data.message, { theme: "colored" })}
        })
      }
    }

    const handleUpdateContact = () => {
      if (Object.keys(validate()).length === 0) {
        updateContact(contact?.id, contact, dispatch).then(resp => {
          if (resp?.status === 200) {
              handleCloseModal(dispatch)
              toast.success("Update successful!", { theme: "colored" })
              window.location.reload()
          }
          else {
            toast.error("Error occured!", { theme: "colored" })
          }
        })
      }
    }

    const handleDeleteContact = (id) => {
      deleteContact(id, dispatch).then(resp => {
        if (resp?.status === 200) {
          handleCloseModal(dispatch)
          toast.success("Deleted successful!", { theme: "colored" })
          window.location.reload()
        }
        else {
          toast.error("Error occured!", { theme: "colored" })
        }
      })
      setContact({ name: '', phoneNumber: ''})
      // setDeleteAction(false)
    }

    const handleCloseModal = () => {
      setContact({ name: '', phoneNumber: '', group:''})
      setFormErrors([])
      setCreateModalState(false)
      setEditModalState(false)
    }

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: 'PhoneNumber',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        sorter: (a, b) => a.phoneNumber.length - b.phoneNumber.length,
      },
      {
        title: 'Group',
        dataIndex: 'group',
        key: 'group',
        sorter: (a, b) => a.group.length - b.group.length
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, record) => <span>{dateFormat(record.createdAt, "dd/mm/yyyy HH:MM")}</span>,
      },
      {
        title: '  Last Updated',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (_, record) => <span>{dateFormat(record.updatedAt, "dd/mm/yyyy HH:MM")}</span>,
      },
      {
        title: 'Actions',
        dataIndex: '',
        key: 'x',
        width: 120,
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title="Update">
              <EditOutlined style={{ fontSize: '20px', color: '#1890ff' }} onClick={() => { setContact({ id: record.id, name: record?.name, phoneNumber: record?.phoneNumber, group: record?.group}); 
              setEditModalState(true) }}/>
            </Tooltip>

            <Tooltip title="Delete">
            <Popconfirm
              title="Delete contact"
              description="Are you sure you want to delete this contact?"
              onConfirm={() => {console.log(record); handleDeleteContact(record?.id)}}
              okText="Yes"
              cancelText="No">
            <DeleteOutlined style={{ fontSize: '20px', color: '#ff4d4f' }}/>
            </Popconfirm>
            </Tooltip>
          </Space>
        )
      }, 
    ];

    const handlePaginationChange = (page, size) => {
      setPageNo(page);
      setPageSize(size);
    };

    return (
        <div className='data-table-page' style={{ height: '85vh'}}>

            <div className='data-table-actions-sections'>  
                <p className='data-table-page-title' style={{marginLeft: '11px'}}>Contacts </p>     
                <Button type="primary" onClick={() => setCreateModalState(true)} >Create Contact</Button>
            </div> 

        <div className='data-table-section' style={{ height: '75vh'}}> 
          <Spin spinning={action.pending}>
              <Table dataSource={allContacts?.data} columns={columns}
                pagination={false}
                scroll={{y: 'calc(75vh - 100px)',}}
                sticky
                className="custom-table"
              /> 
              <div style={{ padding: '15px 5px', textAlign: 'center'}}>
              <Pagination
                className="custom-pagination"
                align='end'
                total={allContacts?.totalElements}
                showTotal={(_, range) => `${range[0]}-${range[1]} of ${allContacts?.totalElements} items`}
                current={pageNo}
                pageSize={pageSize}
                onChange={handlePaginationChange}
                onShowSizeChange={(current, size) => {
                  setPageSize(size);
                  setPageNo(1);
                }}
                showSizeChanger
              />
              </div>
            </Spin>
            </div>   


  {/* /////////////////////CREATING CONTACT //////////// */}
    <Modal title="Create Contact" open={createModalState} okText="Submit" onOk={handleCreateContact} confirmLoading={action.pending}
      onCancel={() => handleCloseModal()} width={600} >
        <Input label="Name " name='name' type='text' value={contact?.name} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.name} />
        <Input label="Phone Number*" name='phoneNumber' type='number' value={contact?.phoneNumber} errorMessage={formErrors?.number}
            handleChange={(e) => handleOnChange(e)} placeholder="Example: 254123456789" />
        <Input label="Group" name='group' type='text' value={contact?.group} handleChange={(e) => handleOnChange(e)} />
    </Modal>


  {/* /////////////////////EDITNG CONTACT //////////// */}
      <Modal title="Update Contact" open={editModalState} okText="Submit" onOk={handleUpdateContact} confirmLoading={action.pending}
        onCancel={() => handleCloseModal()} width={600} >
         <Input label="Name " name='name' type='text' value={contact?.name} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.name} />
          <Input label="Phone Number*" name='phoneNumber' type='number' value={contact?.phoneNumber} errorMessage={formErrors?.number}
              handleChange={(e) => handleOnChange(e)} placeholder="Example: 254123456789" />
          <Input label="Group" name='group' type='text' value={contact?.group} handleChange={(e) => handleOnChange(e)} />
      </Modal>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>
    )
}

export default Contacts