import React, { useState, useEffect } from 'react';
import './templatepreview.css';

const TemplatePreview = ({ templatePreview }) => {
  const [mediaPreview, setMediaPreview] = useState({ image: '', video: '', document: '' });

  // Function to convert the file to base64
  const convertToBase64 = (file, type) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setMediaPreview(prev => ({ ...prev, [type]: reader.result }));
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const { format, text } = templatePreview.component.header;

    if (text) {
      if (format === 'IMAGE' && text.includes('fakepath')) {
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput?.files[0]) {
          convertToBase64(fileInput.files[0], 'image');
        }
      } else if (format === 'VIDEO' && text.includes('fakepath')) {
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput?.files[0]) {
          convertToBase64(fileInput.files[0], 'video');
        }
      } else if (format === 'DOCUMENT' && text.includes('fakepath')) {
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput?.files[0]) {
          convertToBase64(fileInput.files[0], 'document');
        }
      } else {
        // If text is already a valid URL, set it directly
        setMediaPreview(prev => ({ ...prev, [format.toLowerCase()]: text }));
      }
    }
  
    
  }, [templatePreview]);

  const renderMediaPreview = () => {
    const { format, text } = templatePreview.component.header;

    switch (format) {
      case 'TEXT':
        return <p>{text}</p>;
      case 'IMAGE':
        return (
          <img
            src={mediaPreview.image} 
            width="100%"
            height="140px"
            alt="template-media"
          />
        );
      case 'VIDEO':
        return (
          <video width="100%" height="150px" controls>
            <source src={mediaPreview.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case 'DOCUMENT':
        return (
          <div>
            <img src={process.env.PUBLIC_URL+"/images/doc.png"} width="85%" height="120px" alt="template-doc"/>
            <p></p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="whatsapp-template-preview">
      <div className="official-account-info">Preview</div>
      <div className="message-box">
        <div className="header-details">
          {renderMediaPreview()}
        </div>
        <div className="message-text">{templatePreview.component.body.text}</div>
        <div className="footer">
          <span>{templatePreview.component.footer.text}</span>
        </div>
        <div className="template-timestamp">{new Date().toLocaleTimeString()}</div>
      </div>
    </div>
  );
};

export default TemplatePreview;
